<template>
    <div class="content-wrapper">
        <bo-page-title></bo-page-title>
        <div class="content pt-0">
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Laporan</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-7 col-lg-8">
                            <div class="row gx-1">
                                <div class="col-md-4">
                                    <b-form-group class="mb-0">
                                            <v-select placeholder="Pilih kategori Laporan" @input="doFill" v-model="filter.typeLaporan" :options="Config.mr.filterTypeLaporan" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-auto">
                                    <div class="form-group mb-0 d-flex">
                                            <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :opens="'right'"
                                                    :ranges="datePickerConfig.ranges"
                                                    @update="updateValues"
                                            >
                                                    <template v-slot:input="picker">
                                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                        <input class="form-control"
                                                v-model="filter.search"
                                                @input="doSearch"
                                                placeholder="Ketik Nama"
                                        />
                                        <div class="form-control-feedback">
                                                <i class="icon-search4 text-indigo"></i>
                                        </div>
                                                
                                        <b-button
                                                class="ml-1 d-inline-flex align-items-center"
                                                variant="outline-success"
                                                id="resetBtn"
                                                @click="doResetData()"
                                        >Reset</b-button>
                                </div>
                            </div>
                    </div>
                    <div class="alert alert-info mt-2 mb-0">Laporan yang diunduh akan sesuai dengan tanggal yang telah
                        dipilih</div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nama Laporan</th>
                                <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <div class="display-c" v-for="(v,k) in (dataReport||[])" :key="k">
                                <tr class="table-secondary">
                                    <th colspan="99" class="font-weight-semibold">{{v.head||"-"}}</th>
                                </tr>
                                <tr v-for="(v1,k1) in (v.data||[])" :key="k1">
                                    <td>{{k1+1}}</td>
                                    <td>{{v1.name||"-"}}</td>
                                    <td>
                                            <a v-if="v1.slug" @click="downloadLaporan(v1)" href="javascript:;" data-popup="tooltip" v-b-tooltip.hover title="Unduh Laporan"
                                            class="btn btn-icon rounded-round border-success alpha-success"><i class="icon-download"></i></a>
                                            <span v-else> - </span>
                                    </td>
                                </tr>
                            </div>
                            <tr v-if="!(dataReport||[]).length"> 
                            <td colspan="99" class="text-center"> No Data
                            </td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
		
        <b-modal 
            v-model="loadingReport"
			hide-footer
			hide-header
			size="sm"
			no-close-on-backdrop
			class="text-center"
        >
			<div class="d-block text-center p-3 pt-3">
				<b-spinner style="width: 3rem; height: 3rem;" variant="info" label="Large Spinner"></b-spinner>
				<h5 class="text-info">Sedang Melakukan Download Laporan ....</h5>
				<small>*Pengunduhan Laporan akan terjadi dalam waktu beberapa saat</small>
			</div>
		</b-modal>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'


export default {
	extends: GlobalVue,
	data() {
		return {
			loadingReport: false,
			dateRange: {
				startDate: new Date(),
				endDate: new Date(),
			},
			datePickerConfig: {
				startDate: new Date(),
				endDate: new Date(),
				autoApply: true,
				ranges: {
						'Hari Ini': [new Date(), new Date()],
						'7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
						'30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
				},
				applyClass: 'btn-sm btn-primary',
				cancelClass: 'btn-sm btn-light',
				locale: {
						applyLabel: 'Terapkan',
						cancelLabel: 'Batal',
						direction: 'ltr',
						format: 'mm/dd/yyyy',
						separator: ' - ',
				}
			},
			dataReport: [
				{
					head : "REKAM MEDIS",
					type: "RM",
					data : [
						{no : 1, name: "PENGUNJUNG BARU DAN PENGUNJUNG LAMA", slug: "pengunjungBaruLama"},
						{no : 2, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DIAGNOSA UTAMA", slug: "pasienByDiagnosa"},
						{no : 3, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER JENIS PELAYANAN BERDASARKAN GENDER / JENIS KELAMIN", slug: "pasienByGender"},
						{no : 4, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER PELAYANAN BERDASARKAN KUNJUNGAN BARU DAN KUNJUNGAN LAMA", slug: "pasienByKunjungan"},
						{no : 5, name: "JUMLAH KUNJUNGAN BARU DAN KUNJUNGAN LAMA BERDASARKAN GENDER/JENIS KELAMIN", slug: "pasienGenderBaruLama"},
						{no : 6, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienCaraBayar"},
						{no : 7, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DOKTER / PEMBERI PELAYANAN", slug: "pasienByDokter"},
						{no : 8, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DIAGNOSA TINDAKAN", slug: "pasienByICD9"},
						{no : 9, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN AGAMA", slug: ""},
						{no : 10, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN ASAL PASIEN / DOMISILI DALAM KOTA MAGELANG", slug: ""},
						{no : 11, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN ASAL PASIEN / DOMISILI PER KOTA / KABUPATEN", slug: ""},
						{no : 12, name: "JUMLAH  KUNJUNGAN PASIEN RAWAT JALAN PERBULAN PERTRIWULAN DAN PERTAHUN", slug: "pasienPertahun"},
						{no : 13, name: "DAFTAR 10 BESAR PENYAKIT RAWAT JALAN", slug: "get10BesarPenyakit"},
						{no : 14, name: "JUMLAH KASUS PENYAKIT MENULAR BERDASARKAN GENDER/JENIS KELAMIN", slug: "penyakitMenular"},
						{no : 15, name: "JUMLAH KASUS PENYAKIT TIDAK MENULAR BERDASARKAN GENDER/JENIS KELAMIN", slug: "penyakitTidakMenular"},
					]
				},
				{
					head : "SPLPA",
					type: "SPLPA",
					data : [
						{no : 1, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER HARI", slug: "jumlahKunjunganPerhari"},
						{no : 2, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DOKTER / PEMBERI PELAYANAN PER HARI", slug: "jumlahKunjunganPerhariDokter"},
						{no : 3, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN CARA BAYAR / JAMINAN PER HARI", slug: "jumlahKunjunganCaraBayarPerhari"},
						{no : 4, name: "JUMLAH BIAYA PASIEN BERDASARKAN KATEGORI", slug: ""},
						{no : 5, name: "JUMLAH KUNJUNGAN BARU DAN KUNJUNGAN LAMA BERDASARKAN GENDER/JENIS KELAMIN", slug: ""},
						{no : 6, name: "DATA ANAMNESA, DIAGNOSA, RIWAYAT KONTROL DAN TINDAKAN", slug: ""},
						{no : 7, name: "DATA HISTORI PEMERIKSAAN", slug: ""}
					]
				},
				{
					head : "LAINNYA",
					type : "L",
					data : [
						{no : 1, name: "JUMLAH PASIEN BERDASARKAN STATUS PELAYANAN", slug: ""},
						{no : 2, name: "JUMLAH PASIEN BERDASARKAN JENIS RUJUKAN", slug: ""},
						{no : 3, name: "JUMLAH TINDAKAN PELAYANAN GIGI DAN MULUT", slug: ""},
						{no : 4, name: "JUMLAH TINDAKAN RADIODIAGNOSTIK PER JENIS TINDAKAN BERDASARKAN STATUS PELAYANAN PASIEN", slug: "jumlahRadiologiPerlayanan"},
						{no : 5, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN STATUS PELAYANAN", slug: "kunjunganPasienRadiologi"},
						{no : 6, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN GENDER", slug: "jumlahRadiologiPerGender"},
						{no : 7, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienCaraBayarRadiologi"},
						{no : 8, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN JENIS PEMERIKSAAN", slug: "pasienradiologiJenisPemeriksaan"},
						{no : 9, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI PER JENIS PEMERIKSAAN BERDASARKAN KUNJUNGAN BARU DAN KUNJUNGAN LAMA", slug: "pasienradiologiJenisPemeriksaanLamaBaru"},
						{no : 10, name: "JUMLAH PEMERIKSAAN PER JENIS PEMERIKSAAN DI PELAYANAN LABORATORIUM", slug: "jenisPemeriksaanLab"},
						{no : 11, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN GENDER", slug: "pasienLabGender"},
						{no : 12, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienLabCarabayar"},
						{no : 13, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN STATUS PELAYANAN", slug: ""},
						{no : 14, name: "JUMLAH PEMERIKSAAN LABORATORIUM YANG DI RUJUK KE REKANAN", slug: ""},
						{no : 15, name: "JUMLAH PELAYANAN KEBUTUHAN DARAH", slug: ""},
						{no : 16, name: "JUMLAH TINDAKAN PER JENIS TINDAKAN DI PELAYANAN INSTALASI REHABILITASI MEDIS", slug: "jenisTindakanPerRehabilitasiMedis"},
						{no : 17, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN STATUS PELAYANAN", slug: "jenisTindakanPerRehabilitasiMedisStatusLayanan"},
						{no : 18, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN GENDER", slug: "pasienFisioGender"},
						{no : 19, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienFisioCarabayar"},
						{no : 20, name: "JUMLAH PENGUNJUNG DI PELAYANAN INSTALASI REHABILITASI MEDIS", slug: "pasienFisioJumlahPengunjung"},
						{no : 21, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN STATUS PELAYANAN", slug: ""},
					]
				},
			]
		}
	},
	components:{DateRangePicker},
	mounted() {
		let filterColumn = JSON.parse(localStorage.getItem(`kolumn_management_${this.user.id}`) || '[]')
		if(filterColumn.length){
			this.selectedFilter = filterColumn
			this.acceptedFilter = filterColumn
		}
		setTimeout(()=>{
			this.filter.typeLaporan = 'ALL'
		},300)
	},
	methods: {
		doResetData(){
			this.filter.search = ''
			const oriData = [
				{
					head : "REKAM MEDIS",
					type: "RM",
					data : [
						{no : 1, name: "PENGUNJUNG BARU DAN PENGUNJUNG LAMA", slug: "pengunjungBaruLama"},
						{no : 2, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DIAGNOSA UTAMA", slug: "pasienByDiagnosa"},
						{no : 3, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER JENIS PELAYANAN BERDASARKAN GENDER / JENIS KELAMIN", slug: "pasienByGender"},
						{no : 4, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER PELAYANAN BERDASARKAN KUNJUNGAN BARU DAN KUNJUNGAN LAMA", slug: "pasienByKunjungan"},
						{no : 5, name: "JUMLAH KUNJUNGAN BARU DAN KUNJUNGAN LAMA BERDASARKAN GENDER/JENIS KELAMIN", slug: "pasienGenderBaruLama"},
						{no : 6, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienCaraBayar"},
						{no : 7, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DOKTER / PEMBERI PELAYANAN", slug: "pasienByDokter"},
						{no : 8, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DIAGNOSA TINDAKAN", slug: "pasienByICD9"},
						{no : 9, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN AGAMA", slug: ""},
						{no : 10, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN ASAL PASIEN / DOMISILI DALAM KOTA MAGELANG", slug: ""},
						{no : 11, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN ASAL PASIEN / DOMISILI PER KOTA / KABUPATEN", slug: ""},
						{no : 12, name: "JUMLAH  KUNJUNGAN PASIEN RAWAT JALAN PERBULAN PERTRIWULAN DAN PERTAHUN", slug: "pasienPertahun"},
						{no : 13, name: "DAFTAR 10 BESAR PENYAKIT RAWAT JALAN", slug: "get10BesarPenyakit"},
						{no : 14, name: "JUMLAH KASUS PENYAKIT MENULAR BERDASARKAN GENDER/JENIS KELAMIN", slug: "penyakitMenular"},
						{no : 15, name: "JUMLAH KASUS PENYAKIT TIDAK MENULAR BERDASARKAN GENDER/JENIS KELAMIN", slug: "penyakitTidakMenular"},
					]
				},
				{
					head : "SPLPA",
					type: "SPLPA",
					data : [
						{no : 1, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER HARI", slug: "jumlahKunjunganPerhari"},
						{no : 2, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DOKTER / PEMBERI PELAYANAN PER HARI", slug: "jumlahKunjunganPerhariDokter"},
						{no : 3, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN CARA BAYAR / JAMINAN PER HARI", slug: "jumlahKunjunganCaraBayarPerhari"},
						{no : 4, name: "JUMLAH BIAYA PASIEN BERDASARKAN KATEGORI", slug: ""},
						{no : 5, name: "JUMLAH KUNJUNGAN BARU DAN KUNJUNGAN LAMA BERDASARKAN GENDER/JENIS KELAMIN", slug: ""},
						{no : 6, name: "DATA ANAMNESA, DIAGNOSA, RIWAYAT KONTROL DAN TINDAKAN", slug: ""},
						{no : 7, name: "DATA HISTORI PEMERIKSAAN", slug: ""}
					]
				},
				{
					head : "LAINNYA",
					type : "L",
					data : [
						{no : 1, name: "JUMLAH PASIEN BERDASARKAN STATUS PELAYANAN", slug: ""},
						{no : 2, name: "JUMLAH PASIEN BERDASARKAN JENIS RUJUKAN", slug: ""},
						{no : 3, name: "JUMLAH TINDAKAN PELAYANAN GIGI DAN MULUT", slug: ""},
						{no : 4, name: "JUMLAH TINDAKAN RADIODIAGNOSTIK PER JENIS TINDAKAN BERDASARKAN STATUS PELAYANAN PASIEN", slug: "jumlahRadiologiPerlayanan"},
						{no : 5, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN STATUS PELAYANAN", slug: "kunjunganPasienRadiologi"},
						{no : 6, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN GENDER", slug: "jumlahRadiologiPerGender"},
						{no : 7, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienCaraBayarRadiologi"},
						{no : 8, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN JENIS PEMERIKSAAN", slug: "pasienradiologiJenisPemeriksaan"},
						{no : 9, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI PER JENIS PEMERIKSAAN BERDASARKAN KUNJUNGAN BARU DAN KUNJUNGAN LAMA", slug: "pasienradiologiJenisPemeriksaanLamaBaru"},
						{no : 10, name: "JUMLAH PEMERIKSAAN PER JENIS PEMERIKSAAN DI PELAYANAN LABORATORIUM", slug: "jenisPemeriksaanLab"},
						{no : 11, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN GENDER", slug: "pasienLabGender"},
						{no : 12, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienLabCarabayar"},
						{no : 13, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN STATUS PELAYANAN", slug: ""},
						{no : 14, name: "JUMLAH PEMERIKSAAN LABORATORIUM YANG DI RUJUK KE REKANAN", slug: ""},
						{no : 15, name: "JUMLAH PELAYANAN KEBUTUHAN DARAH", slug: ""},
						{no : 16, name: "JUMLAH TINDAKAN PER JENIS TINDAKAN DI PELAYANAN INSTALASI REHABILITASI MEDIS", slug: "jenisTindakanPerRehabilitasiMedis"},
						{no : 17, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN STATUS PELAYANAN", slug: "jenisTindakanPerRehabilitasiMedisStatusLayanan"},
						{no : 18, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN GENDER", slug: "pasienFisioGender"},
						{no : 19, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienFisioCarabayar"},
						{no : 20, name: "JUMLAH PENGUNJUNG DI PELAYANAN INSTALASI REHABILITASI MEDIS", slug: "pasienFisioJumlahPengunjung"},
						{no : 21, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN STATUS PELAYANAN", slug: ""},
					]
				},
			]
			this.dataReport = oriData
		},
		doSearch(){
			const oriData = [
				{
					head : "REKAM MEDIS",
					type: "RM",
					data : [
						{no : 1, name: "PENGUNJUNG BARU DAN PENGUNJUNG LAMA", slug: "pengunjungBaruLama"},
						{no : 2, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DIAGNOSA UTAMA", slug: "pasienByDiagnosa"},
						{no : 3, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER JENIS PELAYANAN BERDASARKAN GENDER / JENIS KELAMIN", slug: "pasienByGender"},
						{no : 4, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER PELAYANAN BERDASARKAN KUNJUNGAN BARU DAN KUNJUNGAN LAMA", slug: "pasienByKunjungan"},
						{no : 5, name: "JUMLAH KUNJUNGAN BARU DAN KUNJUNGAN LAMA BERDASARKAN GENDER/JENIS KELAMIN", slug: "pasienGenderBaruLama"},
						{no : 6, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienCaraBayar"},
						{no : 7, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DOKTER / PEMBERI PELAYANAN", slug: "pasienByDokter"},
						{no : 8, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DIAGNOSA TINDAKAN", slug: "pasienByICD9"},
						{no : 9, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN AGAMA", slug: ""},
						{no : 10, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN ASAL PASIEN / DOMISILI DALAM KOTA MAGELANG", slug: ""},
						{no : 11, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN ASAL PASIEN / DOMISILI PER KOTA / KABUPATEN", slug: ""},
						{no : 12, name: "JUMLAH  KUNJUNGAN PASIEN RAWAT JALAN PERBULAN PERTRIWULAN DAN PERTAHUN", slug: "pasienPertahun"},
						{no : 13, name: "DAFTAR 10 BESAR PENYAKIT RAWAT JALAN", slug: "get10BesarPenyakit"},
						{no : 14, name: "JUMLAH KASUS PENYAKIT MENULAR BERDASARKAN GENDER/JENIS KELAMIN", slug: "penyakitMenular"},
						{no : 15, name: "JUMLAH KASUS PENYAKIT TIDAK MENULAR BERDASARKAN GENDER/JENIS KELAMIN", slug: "penyakitTidakMenular"},
					]
				},
				{
					head : "SPLPA",
					type: "SPLPA",
					data : [
						{no : 1, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER HARI", slug: "jumlahKunjunganPerhari"},
						{no : 2, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DOKTER / PEMBERI PELAYANAN PER HARI", slug: "jumlahKunjunganPerhariDokter"},
						{no : 3, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN CARA BAYAR / JAMINAN PER HARI", slug: "jumlahKunjunganCaraBayarPerhari"},
						{no : 4, name: "JUMLAH BIAYA PASIEN BERDASARKAN KATEGORI", slug: ""},
						{no : 5, name: "JUMLAH KUNJUNGAN BARU DAN KUNJUNGAN LAMA BERDASARKAN GENDER/JENIS KELAMIN", slug: ""},
						{no : 6, name: "DATA ANAMNESA, DIAGNOSA, RIWAYAT KONTROL DAN TINDAKAN", slug: ""},
						{no : 7, name: "DATA HISTORI PEMERIKSAAN", slug: ""}
					]
				},
				{
					head : "LAINNYA",
					type : "L",
					data : [
						{no : 1, name: "JUMLAH PASIEN BERDASARKAN STATUS PELAYANAN", slug: ""},
						{no : 2, name: "JUMLAH PASIEN BERDASARKAN JENIS RUJUKAN", slug: ""},
						{no : 3, name: "JUMLAH TINDAKAN PELAYANAN GIGI DAN MULUT", slug: ""},
						{no : 4, name: "JUMLAH TINDAKAN RADIODIAGNOSTIK PER JENIS TINDAKAN BERDASARKAN STATUS PELAYANAN PASIEN", slug: "jumlahRadiologiPerlayanan"},
						{no : 5, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN STATUS PELAYANAN", slug: "kunjunganPasienRadiologi"},
						{no : 6, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN GENDER", slug: "jumlahRadiologiPerGender"},
						{no : 7, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienCaraBayarRadiologi"},
						{no : 8, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN JENIS PEMERIKSAAN", slug: "pasienradiologiJenisPemeriksaan"},
						{no : 9, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI PER JENIS PEMERIKSAAN BERDASARKAN KUNJUNGAN BARU DAN KUNJUNGAN LAMA", slug: "pasienradiologiJenisPemeriksaanLamaBaru"},
						{no : 10, name: "JUMLAH PEMERIKSAAN PER JENIS PEMERIKSAAN DI PELAYANAN LABORATORIUM", slug: "jenisPemeriksaanLab"},
						{no : 11, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN GENDER", slug: "pasienLabGender"},
						{no : 12, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienLabCarabayar"},
						{no : 13, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN STATUS PELAYANAN", slug: ""},
						{no : 14, name: "JUMLAH PEMERIKSAAN LABORATORIUM YANG DI RUJUK KE REKANAN", slug: ""},
						{no : 15, name: "JUMLAH PELAYANAN KEBUTUHAN DARAH", slug: ""},
						{no : 16, name: "JUMLAH TINDAKAN PER JENIS TINDAKAN DI PELAYANAN INSTALASI REHABILITASI MEDIS", slug: "jenisTindakanPerRehabilitasiMedis"},
						{no : 17, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN STATUS PELAYANAN", slug: "jenisTindakanPerRehabilitasiMedisStatusLayanan"},
						{no : 18, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN GENDER", slug: "pasienFisioGender"},
						{no : 19, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienFisioCarabayar"},
						{no : 20, name: "JUMLAH PENGUNJUNG DI PELAYANAN INSTALASI REHABILITASI MEDIS", slug: "pasienFisioJumlahPengunjung"},
						{no : 21, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN STATUS PELAYANAN", slug: ""},
					]
				},
			]
			let search = this.filter.search
			
			let data = _.filter(oriData, v => {
				 v.data = _.filter(v.data, v2 => {
					 return _.includes(v2.name, search.toUpperCase())
				 })
				 if((v.data||[]).length){
					 return v
				 }
			})

			this.dataReport = data
		},
		doFill(){
			const oriData = [
				{
					head : "REKAM MEDIS",
					type: "RM",
					data : [
						{no : 1, name: "PENGUNJUNG BARU DAN PENGUNJUNG LAMA", slug: "pengunjungBaruLama"},
						{no : 2, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DIAGNOSA UTAMA", slug: "pasienByDiagnosa"},
						{no : 3, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER JENIS PELAYANAN BERDASARKAN GENDER / JENIS KELAMIN", slug: "pasienByGender"},
						{no : 4, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER PELAYANAN BERDASARKAN KUNJUNGAN BARU DAN KUNJUNGAN LAMA", slug: "pasienByKunjungan"},
						{no : 5, name: "JUMLAH KUNJUNGAN BARU DAN KUNJUNGAN LAMA BERDASARKAN GENDER/JENIS KELAMIN", slug: "pasienGenderBaruLama"},
						{no : 6, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienCaraBayar"},
						{no : 7, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DOKTER / PEMBERI PELAYANAN", slug: "pasienByDokter"},
						{no : 8, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DIAGNOSA TINDAKAN", slug: "pasienByICD9"},
						{no : 9, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN AGAMA", slug: ""},
						{no : 10, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN ASAL PASIEN / DOMISILI DALAM KOTA MAGELANG", slug: ""},
						{no : 11, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN ASAL PASIEN / DOMISILI PER KOTA / KABUPATEN", slug: ""},
						{no : 12, name: "JUMLAH  KUNJUNGAN PASIEN RAWAT JALAN PERBULAN PERTRIWULAN DAN PERTAHUN", slug: "pasienPertahun"},
						{no : 13, name: "DAFTAR 10 BESAR PENYAKIT RAWAT JALAN", slug: "get10BesarPenyakit"},
						{no : 14, name: "JUMLAH KASUS PENYAKIT MENULAR BERDASARKAN GENDER/JENIS KELAMIN", slug: "penyakitMenular"},
						{no : 15, name: "JUMLAH KASUS PENYAKIT TIDAK MENULAR BERDASARKAN GENDER/JENIS KELAMIN", slug: "penyakitTidakMenular"},
					]
				},
				{
					head : "SPLPA",
					type: "SPLPA",
					data : [
						{no : 1, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN PER HARI", slug: "jumlahKunjunganPerhari"},
						{no : 2, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN DOKTER / PEMBERI PELAYANAN PER HARI", slug: "jumlahKunjunganPerhariDokter"},
						{no : 3, name: "JUMLAH KUNJUNGAN PASIEN RAWAT JALAN BERDASARKAN CARA BAYAR / JAMINAN PER HARI", slug: "jumlahKunjunganCaraBayarPerhari"},
						{no : 4, name: "JUMLAH BIAYA PASIEN BERDASARKAN KATEGORI", slug: ""},
						{no : 5, name: "JUMLAH KUNJUNGAN BARU DAN KUNJUNGAN LAMA BERDASARKAN GENDER/JENIS KELAMIN", slug: ""},
						{no : 6, name: "DATA ANAMNESA, DIAGNOSA, RIWAYAT KONTROL DAN TINDAKAN", slug: ""},
						{no : 7, name: "DATA HISTORI PEMERIKSAAN", slug: ""}
					]
				},
				{
					head : "LAINNYA",
					type : "L",
					data : [
						{no : 1, name: "JUMLAH PASIEN BERDASARKAN STATUS PELAYANAN", slug: ""},
						{no : 2, name: "JUMLAH PASIEN BERDASARKAN JENIS RUJUKAN", slug: ""},
						{no : 3, name: "JUMLAH TINDAKAN PELAYANAN GIGI DAN MULUT", slug: ""},
						{no : 4, name: "JUMLAH TINDAKAN RADIODIAGNOSTIK PER JENIS TINDAKAN BERDASARKAN STATUS PELAYANAN PASIEN", slug: "jumlahRadiologiPerlayanan"},
						{no : 5, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN STATUS PELAYANAN", slug: "kunjunganPasienRadiologi"},
						{no : 6, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN GENDER", slug: "jumlahRadiologiPerGender"},
						{no : 7, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienCaraBayarRadiologi"},
						{no : 8, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI BERDASARKAN JENIS PEMERIKSAAN", slug: "pasienradiologiJenisPemeriksaan"},
						{no : 9, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN RADIOLOGI PER JENIS PEMERIKSAAN BERDASARKAN KUNJUNGAN BARU DAN KUNJUNGAN LAMA", slug: "pasienradiologiJenisPemeriksaanLamaBaru"},
						{no : 10, name: "JUMLAH PEMERIKSAAN PER JENIS PEMERIKSAAN DI PELAYANAN LABORATORIUM", slug: "jenisPemeriksaanLab"},
						{no : 11, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN GENDER", slug: "pasienLabGender"},
						{no : 12, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienLabCarabayar"},
						{no : 13, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN LABORATORIUM BERDASARKAN STATUS PELAYANAN", slug: ""},
						{no : 14, name: "JUMLAH PEMERIKSAAN LABORATORIUM YANG DI RUJUK KE REKANAN", slug: ""},
						{no : 15, name: "JUMLAH PELAYANAN KEBUTUHAN DARAH", slug: ""},
						{no : 16, name: "JUMLAH TINDAKAN PER JENIS TINDAKAN DI PELAYANAN INSTALASI REHABILITASI MEDIS", slug: "jenisTindakanPerRehabilitasiMedis"},
						{no : 17, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN STATUS PELAYANAN", slug: "jenisTindakanPerRehabilitasiMedisStatusLayanan"},
						{no : 18, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN GENDER", slug: "pasienFisioGender"},
						{no : 19, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN CARA BAYAR / PENJAMINAN", slug: "pasienFisioCarabayar"},
						{no : 20, name: "JUMLAH PENGUNJUNG DI PELAYANAN INSTALASI REHABILITASI MEDIS", slug: "pasienFisioJumlahPengunjung"},
						{no : 21, name: "JUMLAH KUNJUNGAN PASIEN DI PELAYANAN INSTALASI REHABILITASI MEDIS BERDASARKAN STATUS PELAYANAN", slug: ""},
					]
				},
			]
			if(this.filter.typeLaporan != 'ALL'){
				let data = 
				_.filter(oriData, v => {
					return v.type == this.filter.typeLaporan
				})
				this.dataReport = data
			}else{
				this.doResetData()
			}
		},
		momentDate(dates){
				let formatted = moment(dates).format("DD MMM YYYY")
				return formatted
		},
		updateValues(e){
				this.doConvertDate()
		},  
		doConvertDate(){
				this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
				this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
		},
		
        to24Hours(date){
            return moment(date).add(24, 'hours').format('YYYY-MM-DD')
        },

		downloadLaporan(v){
			this.doConvertDate()
			this.loadingReport = true
			
			let data = {
				exptype: 'xlsx', type: "export", startDate: this.filter.startDate, endDate: this.filter.endDate
			}

			let self = this
			$.ajax({
					type: "POST",
					url: process.env.VUE_APP_API_URL + `/report/${v.slug}?token=IXs1029102asoaksoas102901290`,
					data: data,
					cache: false,
					xhrFields:{
							responseType: 'blob'
					},
					success: data => 
					{
							self.loadingOverlay = false
							self.loadingReport = false

							var link = document.createElement('a')
							link.href = window.URL.createObjectURL(data)
							link.download = `${v.name}-${moment().format("YYYY-MM-DD")}.xlsx`
							link.click()
					},
					fail: data => {
							self.loadingOverlay = false
							alert('Not downloaded')
					}
			})
		}
	},
	filters: {
			date(val) {
					return val ? moment(val).format("D MMM YYYY") : ""
			}
	}
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>